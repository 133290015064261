
  import { encodeOpts } from '@/utils/encode-opts';

  export default {
    data() {
      return {
        loading: false,
        productsList: [],
      };
    },
    props: {
      products: Object,
      pagination: Object,
    },
    async mounted() {
      const list = (
        await this.$axios.get(`/products/no-validate`, {
          params: { products: this.products.products.map((el) => el.id), allowCache: true },
          paramsSerializer: encodeOpts,
        })
      ).data;
      for (let i = 0; i < this.products.products.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (list[j]._id === this.products.products[i].id) {
            this.productsList.push(list.splice(j--, 1)[0]);
            this.productsList[this.productsList.length - 1].imgSrc = this.products.products[i].imgSrc;
            this.productsList[this.productsList.length - 1].headingText = this.products.products[i].headingText;
            this.productsList[this.productsList.length - 1].headingHtml = this.products.products[i].headingHtml;
            this.productsList[this.productsList.length - 1].bodyText = this.products.products[i].bodyText;
            this.productsList[this.productsList.length - 1].bodyHtml = this.products.products[i].bodyHtml;
          }
        }
      }
    },
    components: {
      ProductsGridTemplate: () => import('./ProductsGridTemplate'),
    },
  };
