
  import checkProductsPrices from '@/plugins/check.products.prices';
  import { type } from 'os';

  export default {
    mixins: [checkProductsPrices],
    data() {
      return {
        currentPage: 1,
      };
    },
    props: {
      products: Object,
      pagination: Object,
      categorySlug: String,
      loading: Boolean,
      productsList: {
        type: Array,
        default: () => [],
      },
    },
  };
