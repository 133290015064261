import { mapGetters } from 'vuex';

export default {
  name: 'check.products.prices.mixin',
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
  },
  data() {
    return {
      checkingPrices: this.$store.getters.storeInformation.showDecoratedPricesOnProductList,
    };
  },
  methods: {
    async checkDecorationPrices() {
      if (this.storeInformation.showDecoratedPricesOnProductList) {
        try {
          const list = this.productsList || this.availableList || this.recommendedProducts || [];
          if (!list.length) return;
          this.checkingPrices = true;
          const res = await this.$api.products.productsCheck(list);
          res.forEach((product) => {
            for (let i = 0; i < list.length; i++) {
              if (list[i]._id === product._id) {
                ['minQuantity', 'priceMin', 'unitPrice', 'unitCost', 'hasOnlyOnePrice'].forEach((key) => {
                  list[i][key] = product[key];
                });
                if (product.priceMin) {
                  list[i].noPricing = false;
                }
              }
            }
          });
        } catch (e) {
        } finally {
          this.checkingPrices = false;
        }
      }
    },
  },
  async mounted() {
    if (!this.shipCheckPricesOnMount) this.checkDecorationPrices();
  },
};
